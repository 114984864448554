<template>
  <div>
    <PageHeader />
    <b-overlay>
      <b-card v-if="gameDetail">
        <ValidationObserver ref="gameForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <b-form-group
              label="ป้ายเข้าเกม"
              label-cols-sm="12"
              label-cols-lg="2"
            >
              <b-img-lazy
                v-if="typeof previewImageUrl === 'string'"
                :src="previewImageUrl"
                fluid
                rounded
                blank-height="120px"
                blank-src="https://via.placeholder.com/300"
                alt="ป้ายเข้าเกม"
              ></b-img-lazy>
              <div v-if="image || gameData.gameImageUrl" class="d-flex my-2">
                <b-form-text>{{previewImageUrl}}</b-form-text>
                <a href="#" @click="image = null; gameData.gameImageUrl = null "> [ ลบ ] </a>
              </div>
            </b-form-group>
            
            <b-form-group
              v-if="roleName === 'OWNER' || $allowPermission('agent:manage.game')"
              label="ป้ายเข้าเกม"
              label-for="form-image"
              label-cols-lg="2"
            >
              <b-input-group>
                <b-form-file
                  v-model="image"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept="image/*"
                ></b-form-file>
              </b-input-group>
            </b-form-group>

            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('games.name')"
              rules="required"
            >
              <b-form-group
                label-cols-sm="12"
                label-cols-lg="2"
                :label="`${$t('games.name')} *`"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="gameData.name"
                  :disabled="isDisabled"
                  :state="errors[0] ? false : null"
                ></b-form-input>
              </b-form-group>
            </ValidationProvider>
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="2"
              :label="`${$t('games.type')} *`"
              label-for="type"
            >
              <b-form-input
                id="type"
                v-model="gameData.typeString"
                disabled
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="2"
              label="ฟรีเครดิตเล่น"
              label-for="isAllowFreeCredit"
            >
              <b-form-select
                id="isAllowFreeCredit"
                v-model="gameData.isAllowFreeCredit"
                :disabled="isDisabled"
              >
                <b-form-select-option
                  v-for="(opt, key) in freeCredits"
                  :key="key"
                  :value="opt.value"
                  >{{ opt.label }}
                </b-form-select-option>
              </b-form-select>
              <b-form-text>
                เปิด เพื่อทำให้ผู้เล่นที่รับโปรโมชั่นฟรีเครดิต
                สามารถเข้าเล่นเกมได้
              </b-form-text>
            </b-form-group>
            <ValidationProvider
              v-slot="{ errors }"
              name="commissionRate"
              rules="required"
            >
              <b-form-group
                label-cols-sm="12"
                label-cols-lg="2"
                label="ค่าคอมมิทชั่น"
                label-for="slug"
              >
                <b-input-group>
                  <b-form-input
                    id="slug"
                    v-model="gameData.commissionRate"
                    :disabled="isDisabled"
                    type="number"
                    min="0"
                    max="100"
                    :state="errors[0] ? false : null"
                  ></b-form-input>
                </b-input-group>
                <b-form-text>
                  ส่วนแบ่งที่ผู้ให้บริการได้รับ {{ gameDetail.name }} %
                </b-form-text>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              name="orderPosition"
              rules="required"
            >
              <b-form-group
                label-cols-sm="12"
                label-cols-lg="2"
                label="ลำดับการแสดง"
                label-for="orderPosition"
              >
                <b-input-group>
                  <b-form-input
                    id="orderPosition"
                    v-model="gameData.orderPosition"
                    type="number"
                    min="0"
                    max="100"
                    :state="errors[0] ? false : null"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </ValidationProvider>
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="2"
              :label="`${$t('fields.status')}`"
              label-for="status"
            >
              <b-form-select id="status" v-model="gameData.status" class="mb-3">
                <b-form-select-option
                  v-for="(opt, key) in statusOptions"
                  :key="key"
                  :value="opt.value"
                  >{{ opt.label }}
                </b-form-select-option>
              </b-form-select>
              <b-form-text>
                <u>เปิดให้บริการ:</u>
                ผู้เล่นสามารถเข้าเล่นเกมที่เปิดให้บริการอยู่ได้<br />
                <u>ไม่เปิดให้บริการ (ซ่อนจากหน้าเว็บ):</u>
                ผู้เล่นไม่เห็นเกมที่ปิดให้บริการ<br />
                <u>ปิดปรับปรุง (แสดงอยู่ แต่กดเข้าเล่นไม่ได้):</u>
                ผู้เล่นเห็นเกมที่ปิดปรับปรุง แต่เข้าเล่นไม่ได้
              </b-form-text>
            </b-form-group>

            <b-form-group
                v-if="isOwner"
                label-cols-sm="12"
                label-cols-lg="2"
                label="ตั้งค่าคีย์"
            >
              <KeyForm v-model="providerKey" :game-name="gameDetail.rawName" :require-game-key="true"/>
            </b-form-group>
            <div class="text-right">
              <b-button class="mr-2" @click="$router.go(-1)">
                {{ $t('buttons.back') }}
              </b-button>
              <b-overlay
                :show="false"
                rounded
                spinner-small
                class="d-inline-block"
              >
                <b-button type="submit" variant="primary" block>
                  {{ $t('buttons.submit') }}
                </b-button>
              </b-overlay>
            </div>
          </form>
        </ValidationObserver>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {BetLimit} from '@src/views/main/players/bet-limit';

export default {
  page: {
    title: 'Provider game',
  },
  components: {
    KeyForm: () =>
        import('@components/games/key-form'),
  },
  data() {
    return {
      statusOptions: [
        { value: 0, label: 'เปิดให้บริการ' },
        {
          value: 1,
          label: 'ปิดปรับปรุง (แสดงอยู่ แต่กดเข้าเล่นไม่ได้)',
        },
        { value: 2, label: 'ปิดให้บริการ (ซ่อนจากหน้าเว็บ)' },
      ],
      freeCredits: [
        { value: true, label: 'ฟรีเครดิตเล่นได้' },
        { value: false, label: 'ฟรีเครดิตเล่นไม่ได้' },
      ],
      gameData: {
        name: '',
        gameImageUrl: null,
        type: '',
        commissionRate: '',
        status: 0,
        orderPosition: 0,
        isAllowFreeCredit: true,
      },
      image: null,
      providerKey: {}
    }
  },
  computed: {
    ...mapGetters(['isOwner']),
    ...mapState({
      gameDetail: (state) => state.game.gameDetail,
      roleName: (state) => state.user.userInfo.roleName,
    }),
    gameId() {
      return this.$route.params.id
    },
    clientUrl() {
      return `${process.env.VUE_APP_CLIENT_URL}`
    },
    isDisabled() {
      return this.roleName !== 'OWNER'
    },
    previewImageUrl() {
      if (!this.image){
        return this.gameData.gameImageUrl
      }
      return URL.createObjectURL(this.image); 
    }
  },
  watch: {
    gameDetail(val) {
      if (val) {
        this.setData()
      }
    },
  },
  async created() {
    if (this.gameId) {
      this.fetchGame(this.gameId)
      this.setData()
    }
  },
  methods: {
    ...mapActions(['fetchGame', 'updateGame']),
    setData() {
      if (this.gameDetail && this.gameDetail.id) {
        const data = JSON.parse(JSON.stringify(this.gameDetail))
        this.gameData = {
          ...data,
          typeString: this.getGameTypeString(data.type),
        }
        this.providerKey = data.providerKey ? JSON.parse(data.providerKey) : {}

        if (this.providerKey['GAME_BET_LIMIT']) {
          this.providerKey['GAME_BET_LIMIT'] = this.providerKey[
              'GAME_BET_LIMIT'
              ]?.length
              ? JSON.parse(this.providerKey['GAME_BET_LIMIT'])
              : []
          this.providerKey['GAME_BET_LIMIT'] = this.mapValueToBetOption(
              this.providerKey['GAME_BET_LIMIT'],
              this.gameData.rawName
          )
        }
      }
    },
    mapValueToBetOption(valueArr, gamePath) {
      const betLimitOptions = BetLimit[gamePath].options
      const ret = valueArr.map((v) => {
        return betLimitOptions?.find((option) => v === option.value)
      })

      return ret?.filter((v) => !!v)
    },
    getGameTypeString(type) {
      switch (type) {
        case 1:
          return 'สล็อต'
        case 2:
          return 'ยิงปลา'
        case 3:
          return 'คาสิโน'
        case 4:
          return 'กีฬา'
        case 5:
          return 'กราฟ'
      }
    },
    onSubmit() {
      if (this.gameData.id) {
        const formData = new FormData()
        formData.append('name', this.gameData.name)
        formData.append('status', +this.gameData.status)
        formData.append('isAllowFreeCredit', this.gameData.isAllowFreeCredit)
        formData.append('commissionRate', this.gameData.commissionRate)
        formData.append('orderPosition', this.gameData.orderPosition)
        if (this.isOwner) {
          if (Array.isArray(this.providerKey['GAME_BET_LIMIT'])) {
            this.providerKey['GAME_BET_LIMIT'] = JSON.stringify(
                this.providerKey['GAME_BET_LIMIT'].map((bl) => bl.value)
            )
          }
          delete this.providerKey.hasGameKey
          formData.append('providerKey', JSON.stringify(this.providerKey))
        }
        if (this.image) {
          formData.append('image', this.image)
        }

        this.updateGame({ id: this.gameDetail.id, formData })
      }
    },
  },
}
</script>
